import { HttpClient, HttpContext, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable, isDevMode } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';
import { firstValueFrom, of } from 'rxjs';
import { ToasterService } from './toaster.service';
import { DtrumApi } from '@dynatrace/dtrum-api-types';
import { ulid } from 'ulidx';

declare const dtrum: DtrumApi;

export type FetchOptions = {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    context?: HttpContext;
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    body?: any,
    observe?: 'body' | 'events' | 'response';
    reportProgress?: boolean;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
}

@Injectable({
    providedIn: "root"
})
export class Fetch {

    /**
     * Strictly used for dtrum navigation event detection.
     *
     * _This is a monkey-patch. Not to be expanded upon._
     *
     * # Do not use this for ANYTHING else!
     */
    public currentRootPage: string;
    private lastNavigatedPage: string;

    private currentXhrAction: number;
    private currentXhrRequests = {};
    private lastEvent: Event;
    private lastEventAge: number;

    constructor(
        private http: HttpClient,
        private toaster: ToasterService
    ) {
        window.addEventListener("click", this.detectXhrEvent);
        window.addEventListener("touch", this.detectXhrEvent);
        window.addEventListener("keypress", this.detectXhrEvent);
        window.addEventListener("mouseup", this.detectXhrEvent);
        window.addEventListener("mousedown", this.detectXhrEvent);
        window.addEventListener("pointerup", this.detectXhrEvent);
        window.addEventListener("pointerdown", this.detectXhrEvent);
    }

    private detectXhrEvent = ((evt: Event) => {
        this.lastEvent = evt as any;
        this.lastEventAge = Date.now();
    }).bind(this);

    private clearXhrAction(id: string) {
        // console.log("clearing action", id, this.currentXhrRequests)

        const isSettled = (Object.values(this.currentXhrRequests) as any)
            .every(r => {
                return !r.pending && (r.ended < (Date.now() - 500));
            });

        if (isSettled && this.currentXhrAction) {
            // console.log("Settling custom action", id)
            // All requests have settled. Close the xhr action;
            dtrum?.leaveAction(this.currentXhrAction);
            this.currentXhrRequests = {};
            this.currentXhrAction = undefined;
            this.lastEvent = null;
        }
        else if (this.currentXhrAction) {
            setTimeout(() => {
                this.clearXhrAction(id)
            }, 550);
        }
    }

    // Public interface for making AJAX transactions
    public get<T>(url: string, options: FetchOptions = {}, returnError = false): Promise<T> {
        return this.request<T>("get", url, options, returnError);
    }
    public put<T>(url: string, body: any, options: FetchOptions = {}, returnError = false): Promise<T> {
        options.body = (options.body && Object.keys(options.body).length > 0 ? options.body : body) || {};
        return this.request<T>("put", url, options, returnError);
    }
    public post<T>(url: string, body: any, options: FetchOptions = {}, returnError = false): Promise<T> {
        options.body = (options.body && Object.keys(options.body).length > 0 ? options.body : body) || {};
        return this.request<T>("post", url, options, returnError);
    }
    public patch<T>(url: string, body: any, options: FetchOptions = {}, returnError = false): Promise<T> {
        options.body = (options.body && Object.keys(options.body).length > 0 ? options.body : body) || {};
        return this.request<T>("patch", url, options, returnError);
    }
    public delete<T>(url: string, options: FetchOptions = {}, returnError = false): Promise<T> {
        return this.request<T>("delete", url, options, returnError);
    }

    public getOData<T>(url: string, options: FetchOptions = {}, returnError = false):
        Promise<{
            value: T,
            '@odata.context': string;
            '@odata.nextLink': string;
            '@odata.count': number
        }> {
        return (this.request<T>("get", url, options, returnError) as any).then(res => {
            // If the endpoint isn't really odata, fake it.
            if (Array.isArray(res))
                res = { '@odata.count': res.length, value: res } as any;
            return res;
        });
    }

    // Internally, handle the observable as a promise.
    private request<T>(method: string, url: string, options: FetchOptions = {}, returnError = false): Promise<T> {
        options.reportProgress = true;

        // Allow support for different response types.
        // Generally we shouldn't need this to be anything other than JSON.
        options.responseType = options.responseType || "json";
        options.withCredentials = true;

        if (!url || url.length < 3) {
            throw new Error("Cannot " + method + " on URL [" + url + "]");
        }

        const id = ulid();
        if (!this.currentXhrAction && !/\/api\/(?:dynatrace|portal\/notifications|eda\/v1\.0\/notes)/.test(url)) {
            const { page, query } = location.href.match(/#\/(?<page>[^?]+)\??(?<query>.+)?$/)?.groups || {};
            const { asset } = location.href.match(/(?:trail|asset)=(?<asset>[^&]+)/)?.groups || {};
            const tab = window['dynamic_tabs']?.selectedTab?.header;

            //
            if (this.lastEvent && this.lastNavigatedPage == this.currentRootPage) {

                const target = this.lastEvent.target as HTMLElement;
                const name = `(${page}${tab ? `, tab "${tab}"` : ''}${asset ? `, asset "${asset}"`: ''}) ${this.lastEvent.type} on ${(target?.getAttribute?.("data-dtname") || target?.textContent)?.trim()} {${location.hash}}`

                this.currentXhrAction = dtrum?.enterAction(name, "", null, url);
            }
            else {
                this.lastNavigatedPage = this.currentRootPage;

                const name = `Load view "${page}"${tab ? `, tab "${tab}"` : ''}${asset ? `, asset "${asset}"`: ''}`;

                this.currentXhrAction = dtrum?.enterAction(name, "", null, url);
            }
        }

        const p = firstValueFrom(
            this.http.request(method, url, options)
                .pipe(
                    retry({
                        delay(error, retryCount) {
                            if (error.status == 429 || error.status == 502)
                                return of({});

                            if (error.status == 504 && isDevMode())
                                this.toaster.toast("Please disconnect from Dynatrace VPN and connect to the Azure VPN.");

                            throw error;
                        },
                        count: 2
                    }),
                    catchError(err => {
                        if (returnError) {

                            this.currentXhrRequests[id].pending = false;
                            this.currentXhrRequests[id].ended = Date.now();

                            this.clearXhrAction(id);

                            of(err);
                            throw err;
                        }

                        const title = err.error?.title || "Backend failure";
                        const msg = err.error?.message ||
                            (!!(err.errors || [])[0] && JSON.stringify(err.errors[0])) ||
                            err.error?.error ||
                            (typeof err.error == 'string' && /^[^<]/.test(err.error) && err.error) ||
                            err.body ||
                            err.message ||
                            err.title;

                        if (err.status != 403) {
                            this.toaster.warn(title, msg);
                        }

                        return of(null);
                    })
                )
            )
                .then(data => {
                    if (Array.isArray(data)) {
                        for (let i = 0; i < data.length; i++) {
                            let item = data[i];
                            Object.keys(item).forEach(k => {
                                if (typeof item[k] == 'object' && item[k] != null && Object.keys(item[k]).length == 0)
                                    delete item[k];
                            })
                        }
                    }

                    this.currentXhrRequests[id].pending = false;
                    this.currentXhrRequests[id].ended = Date.now();
                    this.clearXhrAction(id);

                    return data;
                });
        // });

        this.currentXhrRequests[id] = { promise: p, pending: true };

        return p as Promise<T>;
    }
}
